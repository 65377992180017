<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>教师排课</el-breadcrumb-item>
        <el-breadcrumb-item>新增教师</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-form ref="form" :model="form" style="padding: 48px 72px" label-position="right" label-width="auto">
      <el-form-item label="教师姓名" prop="name" :rules="[{ required: true,trigger: 'blur', message: '姓名不能为空'},]">
        <el-input v-model="form.name" style="width: 240px"  clearable />
      </el-form-item>

      <el-form-item label="手机号" prop="phone" :rules="[{ required: true,trigger: 'blur', message: '手机号不能为空'},]">
        <el-input v-model="form.phone" style="width: 240px"  clearable />
      </el-form-item>

      <el-form-item label="小程序UID" prop="uid" :rules="[{ required: true,trigger: 'blur', message: 'UID不能为空'},]">
        <el-input v-model="form.uid" style="width: 240px"  clearable />
      </el-form-item>

      <el-form-item label="背景颜色"  prop="uid" :rules="[{ required: true,trigger: 'blur', message: 'UID不能为空'},]">
        <el-color-picker v-model="form.color"></el-color-picker>
      </el-form-item>

      <el-form-item label="状态">
        <el-radio v-model="form.status" :label="0">离职</el-radio>
        <el-radio v-model="form.status" :label="1">在职</el-radio>
      </el-form-item>

      <el-form-item label="教师主管">
        <el-radio v-model="form.is_manage" :label="0">否</el-radio>
        <el-radio v-model="form.is_manage" :label="1">是</el-radio>
      </el-form-item>

      <el-form-item>
        <el-button size="mini" @click="$router.back()">取消</el-button>
        <el-button size="mini" @click="submit" :loading="loading" type="primary">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {  },
  data() {
    return {
      loading: false,
      image: '',
      icon: '',
      form: {
        id:'',
        name: '',
        phone: '',
        uid: '',
        color: '',
        status: 1,
        is_manage: 0,
      }
    }
  },
  methods: {
    ...mapActions('courseScheduling', ['setSchedulingTeacher','getCourseSchedulingTeacherList']),

    onDelete() {
      this.image = ''
      this.form.lock_icon = ''
    },
    onDeleteIcon() {
      this.icon = ''
      this.form.icon = ''
    },
    onSelectIcon(image) {
      this.icon = imageUrl + image.path
      this.form.icon = image.path
    },
    onSelectImage(image) {
      this.image = imageUrl + image.path
      this.form.lock_icon = image.path
    },
    submit() {

      console.log(this.form)

      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          const { data, res_info } = await this.setSchedulingTeacher(this.form)
          this.loading = false
          this.$router.go(-1)
          if (res_info != 'ok') return
          this.$message.success('新增成功！')
        }
      });
    },
  },
  mounted() {
      let id = this.$route.params.id
      this.getCourseSchedulingTeacherList({id:id}).then(res => {
        this.form = res.data
      })
  }
}
</script>
